import html2pdf from 'html2pdf.js';

export const createPdf = {
  init: async (templatePDF: Element, filename?: string): Promise<void> => {
    const date = new Date().toISOString().split('T')[0];
    const options = {
      margin: 0,
      filename: filename ?? `document-${date}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1, useCORS: true },
      jsPDF: { format: 'A4' },
    };

    await html2pdf()
      .from(templatePDF)
      .set(options)
      .toPdf()
      .save();
  },
};
