
import Vue, { VueConstructor, PropType } from 'vue';
import { DetailsThread, OwnUser } from '../messaging.model';

export const TEMPLATE_PDF_ID = '__templatePDF';

interface Refs {
  [TEMPLATE_PDF_ID]: HTMLDivElement;
}

export default (Vue as VueConstructor<Vue & { $refs: Refs }>).extend({
  name: 'DetailsChatPDF',
  props: {
    onDownloadPDF: { type: Function as PropType<(value: boolean, el?: Element) => void>, required: true },
    ownUser: { type: Object as PropType<OwnUser>, required: true },
    selectedThread: { type: Object as PropType<DetailsThread>, required: true },
  },
  data() {
    return {
      isVisible: true,
      TEMPLATE_PDF_ID,
      urlExpReg: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi,
      emailPattern: /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/gi,
      lang: JSON.parse(window.localStorage.getItem('auth_ehealth'))
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
        : 'es',
    };
  },
  computed: {
    appDisplayName(): string {
      return this.$store.state.app.displayName;
    },
    url(): string {
      const formatedApiDomain = window.location.origin;
      return `${formatedApiDomain}${this.$route.path}`;
    },
    currentDate(): string {
      if (this.lang === 'es' || this.lang === 'ca') {
        return (
          this.$moment(new Date()).format('DD') +
          ' de ' +
          this.$moment(new Date()).format('MMMM') +
          ' de ' +
          this.$moment(new Date()).format('YYYY')
        );
      } else {
        return this.$moment(new Date()).format('DD MMMM YYYY');
      }
    },
  },
  async mounted() {
    const templatePDF = this.$refs[TEMPLATE_PDF_ID];
    await this.onDownloadPDF(true, templatePDF);

    this.isVisible = false;
  },

  methods: {
    replaceEmail(text: string) {
      const style = "style='color: #2196f3'";
      let text_replaced = text.replace(this.emailPattern, function(matched_str: string) {
        return '<a ' + style + " href='mailto:" + matched_str + "'>" + matched_str + '</a>';
      });

      text_replaced = text_replaced.replace(this.urlExpReg, function(matched_url: string) {
        return '<a ' + style + " href='" + matched_url + "'>" + matched_url + '</a>';
      });

      return text_replaced;
    },
  },
});
